import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Design and production guidelines</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Making an icon</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Exporting SVGs</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Contribution process</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "design-and-production-guidelines"
    }}>{`Design and production guidelines`}</h2>
    <p>{`Don’t see the icon you need in the library? Make your own! Follow these guidelines to ensure visual consistency and proper formatting.`}</p>
    <ul>
      <li parentName="ul">{`All icons should be unique and not redundant with any existing icons in the system. Search the `}<a parentName="li" {...{
          "href": "/guidelines/iconography/library"
        }}>{`library`}</a>{` for the keyword(s) associated with your proposed new icon to ensure that it is not already represented.`}</li>
      <li parentName="ul">{`All icons should adhere to the `}<a parentName="li" {...{
          "href": "https://w3.ibm.com/design/language/elements/icons/"
        }}>{`IBM Design Language visual style`}</a>{`.`}</li>
      <li parentName="ul">{`All icons should comply with IBM `}<a parentName="li" {...{
          "href": "/guidelines/accessibility/overview"
        }}>{`accessibility standards`}</a>{`.`}</li>
      <li parentName="ul">{`All icons should be usable across all supported platforms and devices.`}</li>
      <li parentName="ul">{`All icons should be understandable by a global audience of users, regardless of nationality or language.`}</li>
    </ul>
    <h2 {...{
      "id": "making-an-icon"
    }}>{`Making an icon`}</h2>
    <ul>
      <li parentName="ul">{`Create a 16 x 16 or 32 x 32 px artboard for each icon.`}</li>
      <li parentName="ul">{`16 px icons should have 1 px padding. 32 px icons should have 2 px padding.`}</li>
      <li parentName="ul">{`Set your workspace up from the start to snap to pixels and round values to whole pixels to avoid correcting shapes later.`}</li>
      <li parentName="ul">{`Never use center borders. Centering can cause half pixels.`}</li>
      <li parentName="ul">{`Avoid using the line tool; use the rectangle tool instead. The line tool will place the vector on half pixels.`}</li>
      <li parentName="ul">{`Be aware of automatic alignments which can place vectors on uneven or half pixels.`}</li>
      <li parentName="ul">{`Ungroup icon layers completely. The icon should be on the top-most layer in your artboard.`}</li>
      <li parentName="ul">{`Make sure to properly name layers and artboards `}<em parentName="li">{`(these names will also be exported into the code)`}</em>{`.`}</li>
      <li parentName="ul">{`Review the `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/icons/master"
        }}>{`icon master file`}</a>{` to see these guidelines in practice.`}</li>
    </ul>
    <h3 {...{
      "id": "production-ready"
    }}>{`Production-ready`}</h3>
    <p>{`To be considered production-ready, all icon submissions must be delivered in SVG format at 16 x 16 px (for integration with Carbon components) or 32 x 32 (for service icons).`}</p>
    <ul>
      <li parentName="ul">{`Icons should be at whole pixels. No decimals are allowed in x and y coordinates or width and height fields.`}</li>
      <li parentName="ul">{`Each artboard and the artwork within it must be aligned to the pixel grid.`}</li>
      <li parentName="ul">{`All strokes must be expanded and at full pixel values.`}</li>
      <li parentName="ul">{`All possible shapes and paths should be combined.`}</li>
    </ul>
    <h2 {...{
      "id": "exporting-svgs"
    }}>{`Exporting SVGs`}</h2>
    <h3 {...{
      "id": "export-svgs-from-sketch"
    }}>{`Export SVGs from Sketch`}</h3>
    <ol>
      <li parentName="ol">{`Draw a 16 x 16 or 32 x 32 px artboard. 16 px icons should have 1 px padding. 32 px icons should have 2 px padding.`}</li>
      <li parentName="ol">{`Place the icon squarely on the artboard, making sure it’s aligned to the pixel grid.`}</li>
      <li parentName="ol">{`Convert all strokes to outlines `}<inlineCode parentName="li">{`(Shift + ⌘ + O)`}</inlineCode></li>
      <li parentName="ol">{`Select any overlapping shapes and click the Union icon from the top navigation to merge all of the shapes together.`}</li>
      <li parentName="ol">{`Make sure the icon is at `}<inlineCode parentName="li">{`#000000`}</inlineCode>{` and has no additional styling.`}</li>
      <li parentName="ol">{`Select the entire artboard (not just the icon).`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Make Exportable`}</inlineCode>{` at the bottom of the right toolbar in Sketch.`}</li>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`Export`}</inlineCode>{` widget select “SVG” in the format dropdown.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Export 'Artboard-Name'`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Name icon with the `}<inlineCode parentName="li">{`#name--modifier`}</inlineCode>{` convention `}<em parentName="li">{`(i.e.`}</em>{` `}<inlineCode parentName="li">{`copy.svg`}</inlineCode>{`, `}<inlineCode parentName="li">{`copy--glyph.svg`}</inlineCode>{`, `}<inlineCode parentName="li">{`add.svg`}</inlineCode>{`, `}<inlineCode parentName="li">{`add--glyph.svg`}</inlineCode>{`).`}</li>
    </ol>
    <h3 {...{
      "id": "export-svgs-from-adobe-illustrator"
    }}>{`Export SVGs from Adobe Illustrator`}</h3>
    <ol>
      <li parentName="ol">{`Draw a 16 x 16 or 32 x 32 px artboard. 16 px icons should have 1 px padding. 32 px icons should have 2 px padding.`}</li>
      <li parentName="ol">{`Place the icon squarely on the artboard, making sure it’s aligned to the pixel grid.`}</li>
      <li parentName="ol">{`Expand all strokes `}<inlineCode parentName="li">{`Object`}</inlineCode>{` → `}<inlineCode parentName="li">{`Expand`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Select all overlapping shapes and click the “Unite” icon in the Pathfinder panel to merge all of the shapes together.`}</li>
      <li parentName="ol">{`Make sure the icon is at `}<inlineCode parentName="li">{`#000000`}</inlineCode>{` and has no additional styling.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`File`}</inlineCode>{` → `}<inlineCode parentName="li">{`Save a Copy...`}</inlineCode>{` from the top navigation.`}</li>
      <li parentName="ol">{`On the `}<inlineCode parentName="li">{`Format`}</inlineCode>{` dropdown select “SVG.”`}</li>
      <li parentName="ol">{`Below `}<inlineCode parentName="li">{`Format`}</inlineCode>{` select `}<inlineCode parentName="li">{`Use Artboard`}</inlineCode>{`, then select either all or a range of artboards, depending on your need.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`SVG Options`}</inlineCode>{` dialog will then open.`}</li>
      <li parentName="ol">{`Make sure the preferences are the same as in the image below.`}</li>
    </ol>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "476px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "135.29411764705884%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAACxElEQVRIx5WVy04bQRBF52eIsDHGHtsY8Aubt8GgbJIo5COQkk+JskBkE8SWx6cgZZc/QUpX+pR8R0MbpLC4qp6e6tv1uDWT3d3dPd3f31u0IcKE29tbe3h4sOvra7u6urLLy0u7ubmxuW8KnQ1Zt9u1TqcTNjY2DPAsxH1rtVoF2u22772CgM3yPA84j0ajMBwObTAY2NbWlttGo2ErKytWr9et2Ww62HsBQTaDbHt72zY3N/1lvOC/wQXpOtPD2tqah97v963X63l6y8vLVq1WC9RqNVtdXX0WsYi09ghZkGZM21MFRI1lj1KA9fV1JxVhGm1BSKo4kzaNYa0maQ8QNWRkU46wjGzeEE8VRx3gkrQJr0W1QEg6So36ERXRVCoVJxe4rAw1cYGQZpCmtEftJpNJUUPqyWXssd7Z2XFw5sWmpHKAWGRYyCiJdMo+4HKVYqGGSnk8HhfdFMppl1NPyZyQiJAMqQBqSDQQSzqQMjECWkyHYCFlmgDUpKOjIzs8PHRQO9UPK/mk3S8IpTcild50kZpGxLu7u0UW+MriJ2InhEyp4sALRo30ZLmI9GkYPuUPiUbXCbkdCRCBJCLZcGh/f98tdQOquRqJ397engdElE44nU49HXB8fOyWGh4cHDghBzjIxfhzKWfwxQdVsP9MNrPZrCDmpeaXNWnqmQjT92X5FJOikeNZsyyrtepUfl4QNh0tz/FLYn0LnJDuAn0L9ayLys3CqrOvCruZR821ovbanWhbb/oNpKRZ3oz1qC5FvLN6ZckatcpCjcoTkdY29c3avXHof7iw4aeLMPr8zbrTL9aNMtC/RZ0ElIfG8U7TNS9NmIs+/pen5/b+++/w8cejnf/8Y5Ovv2w0HNjJySzi5NknDFL0eHp6Wnwf0ebZ2Rlyc9Ks02495bkXODSZ3bhOf+7pB6S8nkce5vbvP5onkLBVEq9FAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "export an icon from illustrator",
        "title": "export an icon from illustrator",
        "src": "/static/d688017c5a5ec6e12e71707115994372/14f9f/iconography-contribution-exportsvgfromai.png",
        "srcSet": ["/static/d688017c5a5ec6e12e71707115994372/d6747/iconography-contribution-exportsvgfromai.png 288w", "/static/d688017c5a5ec6e12e71707115994372/14f9f/iconography-contribution-exportsvgfromai.png 476w"],
        "sizes": "(max-width: 476px) 100vw, 476px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "contribution-process"
    }}>{`Contribution process`}</h2>
    <p>{`Would your icon be useful for other products? If so, please consider contributing to the design system.`}</p>
    <p>{`To submit, open a pull request with your icon in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/tree/master/packages/icons"
      }}>{`carbon-icons`}</a>{` repository.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      